<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h3 class="card-title">
          <input
            type="text"
            class="form-control"
            value="başlık"
            v-model="title"
          />
        </h3>
        <div>
          <button class="btn btn-primary" @click="newItem">Yeni Başlık</button>
        </div>
      </div>
      <div class="card-body">
        <div class="item2-gl">
          <div class="row">
            <div class="col-lg-12">
              <div class="panel-group1" id="accordion2">
                <div
                  class="panel panel-default mb-4 border p-0"
                  v-for="(item, i) in list"
                  :key="'faq' + i"
                >
                  <div class="panel-heading1">
                    <h4 class="panel-title1 input-group" style="margin: -1px">
                      <input
                        type="text"
                        class="form-control"
                        value="başlık"
                        placeholder="Yeni Başlık"
                        v-model="item.title"
                      />
                      <button class="btn btn-primary" @click="removeItem(i)">
                        <i class="fa fa-trash-o"></i>
                      </button>
                      <a
                        class="
                          btn btn-warning btn-sm
                          accordion-toggle
                          collapsed
                        "
                        data-bs-toggle="collapse"
                        data-parent="#accordion2"
                        :href="'#collapse' + i"
                        aria-expanded="false"
                      ></a>
                    </h4>
                  </div>
                  <div
                    :id="'collapse' + i"
                    class="panel-collapse active collapse"
                    role="tabpanel"
                    aria-expanded="false"
                    style=""
                  >
                    <div class="panel-body bg-white">
                      <div class="card">
                        <div class="card-header">
                          <div class="card-options">
                            <button
                              class="btn btn-primary"
                              @click="addSubItem(i)"
                            >
                              Yeni Alt Başlık
                            </button>
                          </div>
                        </div>
                        <div class="card-body">
                          <div v-for="(t, a) in item.subList" :key="a">
                            <h4
                              class="panel-title1 input-group mb-4"
                              style="margin: -1px"
                            >
                              <input
                                type="text"
                                class="form-control"
                                value="başlık"
                                placeholder="Yeni Başlık"
                                v-model="t.title"
                              />
                              <button
                                class="btn btn-primary"
                                @click="removeSubItem(i, a)"
                              >
                                <i class="fa fa-trash-o"></i>
                              </button>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" id="saveButton" @click="save">
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
  <style>
html {
  scroll-behavior: smooth;
}
.panel-heading1 a.collapsed:before {
  right: 5px;
  top: 7px;
}
.panel-heading1 a:before {
  right: 5px;
  top: 7px;
}
</style>
      <script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Danışan Formu Oluştur";
    this.$store
      .dispatch("clientFormGet", { id: this.$route.params.id })
      .then((value) => {
        this.list = value.list;
        this.title = value.title.title;
      });
  },
  data() {
    return {
      list: [],
      title: "Danışan Formu Oluştur",
    };
  },
  methods: {
    addItem() {
      this.list.push({});
    },
    newItem() {
      this.list.push({ title: "", subList: [] });
      $(function (e) {
        $(".demo-accordion").accordionjs();
      });
      setTimeout(() => {
        this.runContent(this.faqlist.length - 1);
      }, 500);
    },
    addSubItem(a) {
      this.list[a].subList.push({ title: "" });
    },
    removeItem(id) {
      Swal.fire({
        title: "Soru silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.list.splice(id, 1);
          this.$vToastify.success(
            "Soru başarılı bir şekilde silindi",
            "Başarılı!"
          );
        }
      });
    },
    removeSubItem(i, id) {
      Swal.fire({
        title: "Soru silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.list[i].subList.splice(id, 1);
          this.$vToastify.success(
            "Item başarılı bir şekilde silindi",
            "Başarılı!"
          );
        }
      });
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let save = {
        id: this.$route.params.id,
        title: this.title,
        list: JSON.stringify(this.list),
      };
      this.$store.dispatch("clientFormUpdate", save).then((value) => {
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
        this.$vToastify.success(
          "Form başarılı bir şekilde kaydedildi",
          "Başarılı!"
        );
      });
    },
  },
};
</script>